import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import styled from "styled-components";

// @ts-ignore
import dreamlandBuilding from "../images/dreamland-building.jpeg";
// @ts-ignore
import handPictures from "../images/intro-picture.jpeg";
// @ts-ignore
import tableSection from "../images/table-section.jpeg";
// @ts-ignore
import { ReactComponent as InstagramLogo } from "../images/instagram-logo.svg";
// @ts-ignore
import cocoKitchen from "../images/coco-kitchen.jpeg";
// @ts-ignore
import tomatoPasta from "../images/tomato-pasta.jpeg";

import { useScreenSizes } from "../hooks/use-screens/useScreenSizes";

import About from "./About";

const StyledTypography = styled(Typography)`
  && {
    font-family: "Lemonada", cursive;
    font-weight: 700;
    line-height: 1.57rem;
    word-spacing: -0.5px;
    letter-spacing: 0.1rem;
  }
`;

const Home = () => {
  const { isDevice } = useScreenSizes();

  // const [open, setOpen] = useState(true);

  // const handleClose = () => setOpen(false);

  // const openingHours = [
  //   { day: "Friday", hours: "4PM - 10PM", date: "Dec 27" },
  //   { day: "Saturday", hours: "4PM - 10PM", date: "Dec 28" },
  //   { day: "Sunday", hours: "4PM - 9PM", date: "Dec 29" },
  //   { day: "Monday", hours: "4PM - 9PM", date: "Dec 30" },
  //   { day: "Tuesday", hours: "4PM - 9PM", date: "Dec 31" },
  //   { day: "Wednesday", hours: "CLOSED", date: "Jan 1" },
  //   { day: "Thursday", hours: "5PM - 10PM", date: "Jan 2" },
  //   { day: "Friday", hours: "4PM - 10PM", date: "Jan 3" },
  //   { day: "Saturday", hours: "4PM - 10PM", date: "Jan 4" },
  // ];

  return (
    <>
      <Container
        sx={{
          height: isDevice ? "" : "100vh",
          margin: "auto",
          backgroundImage: `url(${cocoKitchen})`,
          backgroundSize: isDevice ? "cover" : "cover",
          backgroundPosition: isDevice ? "30%" : "center",
          backgroundRepeat: "no-repeat",
        }}
        maxWidth={false}
      >
        <Box
          display="grid"
          gridTemplateColumns={isDevice ? "1fr" : "0.5fr 0.75fr"}
          alignItems="center"
          margin="auto"
          height="100%"
          width="100%"
          gap="12rem"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems={isDevice ? "flex-end" : "flex-start"}
            margin={isDevice ? "30% 0 5rem 0" : "35% auto 0 0"}
            width="100%"
          >
            <Box textAlign={isDevice ? "right" : "left"}>
              <StyledTypography
                variant={isDevice ? "h5" : "h4"}
                color="#ffee00"
                sx={{ textShadow: "0px 0px 11px #CCC347" }}
                mb={isDevice ? "0.5rem" : "1rem"}
              >
                Lunch
              </StyledTypography>
              <StyledTypography
                variant="subtitle2"
                mb="1.5rem"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
              >
                Saturdays <br /> 11:30AM - 3PM
              </StyledTypography>

              <StyledTypography
                variant={isDevice ? "h5" : "h4"}
                color="#ffee00"
                sx={{ textShadow: "0px 0px 11px #CCC347" }}
                mb={isDevice ? "0.5rem" : "1rem"}
              >
                Dinner
              </StyledTypography>
              <StyledTypography
                variant="subtitle2"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
              >
                Tues - Thurs
              </StyledTypography>
              <StyledTypography
                mb="1rem"
                variant="subtitle2"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
              >
                5PM - 10PM
              </StyledTypography>
              <StyledTypography
                variant="subtitle2"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
                mt="-1rem"
              >
                Fri - Sat
              </StyledTypography>
              <StyledTypography
                mb="1.5rem"
                variant="subtitle2"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
              >
                4PM - 10PM
              </StyledTypography>
              <StyledTypography
                variant="subtitle2"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
              >
                262 Preston St, <br /> Ottawa, ON <br /> K1R 7R5
              </StyledTypography>
              <StyledTypography
                variant="subtitle2"
                color="#eeeee6"
                sx={{ textShadow: "0px 0px 11px #292928" }}
              >
                <a href="tel:6134224200" style={{ color: "inherit", textDecoration: "none" }}>
                  (613)-422-4200
                </a>
              </StyledTypography>
            </Box>
            <Button
              href="https://www.instagram.com/thedreamlandcafe?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              variant="text"
            >
              <InstagramLogo />
            </Button>
          </Box>
          {!isDevice && (
            <Box
              display="flex"
              // gap="2rem"
              alignItems={"center"}
              justifyContent={"flex-end"}
              margin="0 auto"
            >
              <Box
                display="flex"
                flexDirection="column"
                // gap="2rem"
                width="20rem"
              >
                <img
                  width="100%"
                  height="459px"
                  src={dreamlandBuilding}
                  alt="Dreamland Building"
                  style={{
                    // borderRadius: '20px',
                    opacity: "0.85",
                    // boxShadow: '0px 5px 2px 0px #9eb692',
                  }}
                />

                <img
                  width="100%"
                  height="251px"
                  src={handPictures}
                  alt="Hand Pictures"
                  style={{
                    // borderRadius: '20px',
                    opacity: "0.8",
                    // boxShadow: '0px 5px 2px 0px #f4accd',
                  }}
                />
              </Box>
              <Box width="19rem">
                <img
                  width="100%"
                  height="251px"
                  src={tomatoPasta}
                  alt="Hand Pictures"
                  style={{
                    // borderRadius: '20px',
                    // boxShadow: '0px 5px 2px 0px #CCC347',
                    // marginBottom: '2rem',
                    opacity: "0.8",
                    marginBottom: "-1rem",
                  }}
                />

                <img
                  width="100%"
                  height="420px"
                  src={tableSection}
                  alt="Table Section"
                  style={{
                    // borderRadius: '20px',
                    opacity: "0.8",
                    // boxShadow: '0px 5px 2px 0px #9eb692',
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
      <About />
      {/* <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
        <>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: 400 }, // Adjust width for smaller screens
              maxHeight: "90vh", // Prevent overflow on smaller screens
              overflowY: "auto", // Add scrolling if content overflows vertically
              border: "2px solid #000",
              boxShadow: (theme) => theme.shadows[5],
              p: 4,
            }}
            bgcolor={"#e9b6ce"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box margin={"0  0 0 auto"} onClick={handleClose} sx={{ cursor: "pointer" }}>
              {" "}
              <Typography variant="h6">X</Typography>
            </Box>
            <Box mb={2}>
              <Typography id="modal-modal-title" variant="h3">
                Holiday Hours
              </Typography>
            </Box>
            <Box sx={{ padding: "1rem", margin: "0 auto" }}>
              <Grid container spacing={2}>
                {openingHours.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid container item spacing={2} alignItems={"center"} justifyContent={"center"}>
                      <Grid item xs={4} textAlign="center">
                        <Typography variant="subtitle2">{item.day}</Typography>
                      </Grid>
                      <Grid item xs={4} textAlign="center">
                        <Typography variant="subtitle2">{item.hours}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" textAlign="center">
                          {item.date}
                        </Typography>
                      </Grid>
                    </Grid>
                    {index < openingHours.length - 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </>
      </Modal> */}
    </>
  );
};

export default Home;
